import React, { useMemo } from "react";
import { PageProps, graphql } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import { Hero, StandardGridLayout, CollectionFilterList, ProductCard } from "@global";
import { Container } from "@util/standard";
import {
  calculateGridItemBorders,
  checkCollectionProductLength,
  sortProductsByCategoryOrder,
  splitArrayByCategory,
} from "@util/helper";
import Layout from "@shared/layout";
import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";

interface Props extends PageProps {
  data: Query;
  pageContext: { collectionSlug: string; slug: string };
}

const FilterContainer = styled(Container)`
  width: 29.5%;
  min-width: 400px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    min-width: 0px;
    width: 80%;
    margin: auto;
    padding: 0 0 30px 0;
    border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  }
`;

function RangeTemplate({ data }: Props) {
  const range = data.allSanityRange.nodes[0];
  const products = data.allSanityProduct.nodes;

  if (range == null) {
    return null;
  }

  const collectionSlugs = range.rangeCollections?.map(collection => collection?.slug?.current);

  const collectionChunks =
    collectionSlugs && useMemo(() => splitArrayByCategory(products, collectionSlugs), []);

  const filteredCollections =
    range.rangeCollections &&
    range.rangeCollections.filter(collection => {
      if (collection == null) return null;
      const collectionLength = checkCollectionProductLength(collection);
      return collectionLength && collectionLength > 0;
    });

  return (
    <Layout>
      <Hero title={range.title} data={range.hero} />
      <Container width="100%" tabletFlexDirection="column">
        <FilterContainer>
          {range.title && range.rangeCollections && (
            <CollectionFilterList
              title={range.title}
              rangeSlug={range.slug}
              data={filteredCollections}
              range={range}
              mobileTitleOverride="Collections"
            />
          )}
        </FilterContainer>

        {collectionChunks && (
          <Container margin="0 2% 0 0" flexDirection="column">
            {collectionChunks.map((chunk, index) => {
              if (chunk == null || chunk[0] == null) return null;
              const textData =
                chunk[0].categories && chunk[0].categories[0]?.collectionRef?.collectionDescription;
              const collection = chunk[0].categories && chunk[0].categories[0]?.collectionRef;

              const sortedProducts = sortProductsByCategoryOrder(collection!, chunk);
              if (sortedProducts == null) return null;

              const lastItem = sortedProducts.indexOf(chunk[chunk.length - 1]) + 1;

              return (
                <StandardGridLayout textData={textData} index={index} key={index}>
                  {sortedProducts.map((product, index) => {
                    if (product == null) return null;

                    const { lastRowHasOne, lastRowHasTwo, lastRowHasThree, noBorderLeft } =
                      calculateGridItemBorders(index, lastItem, sortedProducts.length);

                    const noBorderBottom = lastRowHasOne || lastRowHasTwo || lastRowHasThree;

                    return (
                      <ProductCard
                        data={product}
                        key={product.id}
                        hasBorderLeft={!noBorderLeft}
                        hasBorderBottom={!noBorderBottom}
                      />
                    );
                  })}
                </StandardGridLayout>
              );
            })}
          </Container>
        )}
      </Container>
    </Layout>
  );
}

export default RangeTemplate;

export const query = graphql`
  query allSanityRangeQuery($slug: String, $countryCode: String) {
    allSanityRange(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityRange
      }
    }
    allSanityCollection {
      nodes {
        slug {
          current
        }
      }
    }
    allSanityProduct(
      filter: { regions: { elemMatch: { region: { countryCode: { eq: $countryCode } } } } }
    ) {
      nodes {
        ...sanityProductPreview
        categories {
          collectionRef {
            slug {
              current
            }
            collectionDescription {
              _rawBlockContent
            }
            collectionCategories {
              title
              id
            }
            title
          }
        }
        regions {
          region {
            countryCode
          }
        }
      }
    }
  }
`;
